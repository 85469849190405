import React from "react";
import Modalpopup from "./Modalpopup";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <h1>Choux Pastry</h1>
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <a href="tel:0617483685">
            <span>06-17483685</span>
          </a>
          <a href="mailto:chouxpastrys@hotmail.com">
            <span>chouxpastrys@hotmail.com</span>
          </a>
          <span><Modalpopup /></span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
