import React from "react";
import { MilkIcon } from 'react-allergens';
import { EggIcon } from 'react-allergens';
import { GlutenIcon } from 'react-allergens';

const Content = () => {
 
  return (
    <div id="sectionAllergies" className="work-section-wrapper">
      <div className="work-section-top">
        <h1 className="primary-heading">Allergies</h1>
      </div>
      <div className="work-section-bottom">
          <div className="work-section-info">
            <div className="info-boxes-img-container">
              <MilkIcon width={125} height={125} outerColor="goldenrod" />
            </div>
          </div>
          <div className="work-section-info">
            <div className="info-boxes-img-container">
              <EggIcon width={125} height={125} outerColor="goldenrod" />
            </div>
          </div>
          <div className="work-section-info">
            <div className="info-boxes-img-container">
              <GlutenIcon width={125} height={125} outerColor="goldenrod" />
            </div>
          </div>
      </div>
    </div>
  );
};


export default Content;
