import React from "react";
import Navbar from "./Navbar";
import Banner from "./Banner";

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <Banner />
      </div>
    </div>
  );
};

export default Home;
