import React, { useState } from "react";


export default function ScrollIndicator() {

    const [scroll, setScroll] = useState(0);

    const onScroll = () => {
        const winScroll = document.body.scrollTop ||
                          document.documentElement.scrollTop;
        const maxHeight = document.documentElement.scrollHeight -
                          document.documentElement.clientHeight;
        const scrolledPercent = (winScroll / maxHeight) * 100;
        setScroll(scrolledPercent);
    };

    const scrollString = Math.trunc(scroll);

    window.addEventListener("scroll", onScroll);

    return (
        <div className="scrollContainer">
            <div style={{ width: `${scroll}%` }} 
                 className="scrollIndicator">
            </div>
        </div>
    );
}