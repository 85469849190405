import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'black',
    border: '1px solid goldenrod',
    boxShadow: 24,
    p: 4,
};
function Modalpopup() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div>
            <Button style={{ color: 'goldenrod', textDecoration: 'underline'}}onClick={handleOpen}>Terms and Conditions</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{color: 'goldenrod'}}>
                        Terms and Conditions
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2, overflow: 'auto', maxHeight: '400px', maxWidth: '400px' }}>
                    {'General Terms and Conditions of Choux Pastry\n\nArticle 1.\n\nDefinitions\n\n 1.1. In these general terms and conditions, the following terms are used in the following meanings, unless explicitly indicated otherwise or apparent from the context:\n\na. chouxpastry.nl: the user of these general terms and conditions: chouxpastry.nl;\n\nb. customer: the natural person or legal entity who places an order via the website with chouxpastry.nl;\n\nc. agreement: the agreement between chouxpastry.nl and the customer;\n\nd. product: the pastry, and/or a related product, delivered by chouxpastry.nl;\n\ne. website: the website www.chouxpastry.nl;\n\nf. consumer: the customer being a natural person who does not act for purposes related to their trade, business, craft, or profession.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 2. General\n\n2.1. These general terms and conditions apply to every agreement between chouxpastry.nl and the customer.\n\n2.2. Any deviations from these general terms and conditions are only valid if they have been explicitly agreed upon via email.\n\n2.3. If one or more of the provisions in these general terms and conditions are null and void or might be nullified, the remaining provisions of these general terms and conditions remain fully applicable. chouxpastry.nl and the customer will then enter into consultation in order to agree on new provisions to replace the null and void or nullified provisions, taking as much as possible the purpose and intent of the original provisions into account.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 3. Offers and Assortment\n\n3.1. All offers and quotes are non-binding unless indicated otherwise. Quotes are valid for 30 days.\n\n3.2. Offers or quotes do not automatically apply to future agreements.\n\n3.3. chouxpastry.nl is not bound to her offer if there are printing, typing, or programming errors in her mailings, quotes, or on the website. chouxpastry.nl is not bound to a quote or offer if the quote and/or offer is based on an incorrect calculation.\n\n3.4. The assortment offered on the website can be changed.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 4. Images and Other Information\n\n4.1. All images, dimensions, colors, weights, etc., of the offered products listed on the website are approximate and cannot lead to compensation and/or dissolution.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 5. Conclusion of the Agreement\n\n5.1. The agreement is concluded after the customer: has accepted the offer and/or quote from chouxpastry.nl via email.\n\n5.2. After the agreement has been concluded, chouxpastry.nl sends the customer an email confirmation that chouxpastry.nl has received the order. This confirmation email includes the order number and other details of the customer\'s order.\n\n5.3. If an order has been placed, then the customer has indicated that they agree with these general terms and conditions.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 6. Prices and Delivery Costs\n\n6.1. The prices quoted in offers are inclusive of VAT, unless indicated otherwise.\n\n6.2. The prices listed on the website include VAT and exclude delivery costs.\n\n6.3. The delivery costs are clearly made known to the customer before the customer confirms the order.\n\n6.4. The prices of the products and the delivery costs can be changed by chouxpastry.nl from time to time.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 7. Payment\n\n7.1. The customer being a consumer can pay for the products ordered via the website via iDEAL.\n\n7.2. When paying in one of the ways mentioned in article 7.1, the conditions of the respective provider of the payment product apply.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 8. Delivery\n\n8.1. If a product ordered cannot be delivered unexpectedly, chouxpastry.nl will contact the customer as soon as possible. In such a case, the customer can choose to accept a replacement product or to dissolve the agreement.\n\n8.2. The delivery times and associated rates are stated via email before the agreement.\n\n8.3 The order can be delivered in Zoetermeer, or picked up in Zoetermeer when the customer resides outside of Zoetermeer.\n\n8.4. The standard delivery of the order is between on the chosen date and time, agreed upon by the customer and chouxpastry.nl.\n\n8.5. The delivery date given by chouxpastry.nl is only indicative.\n\n8.6. The order is not delivered on Sundays and nationally recognized holidays.\n\n8.7. The customer is obliged to accept the product. If the customer fails to do so, the resulting costs are for his account, such as the costs for delivering the product again. Not accepting the delivered product is at the risk of the customer. Due to the limited shelf life of a product, it may not be possible to deliver a product again. In such a case, the agreed compensation remains due.\n\n8.8. The order cannot be sent to a PO box.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 9. Cancellation\n\n9.1. Cancellation must be done via email and before making the payment.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 10. Right of Withdrawal for Consumers\n\n10.1. After the product has been manufactured according to the customer\'s specifications, the customer cannot dissolve the agreement, because the product can spoil or age quickly, such as choux pastry.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 11. Liability and Limitation\n\n11.1. chouxpastry.nl cannot be held responsible for any damage that is a direct or indirect result of: a. an event that is actually beyond her control and thus cannot be attributed to her actions and/or omissions, as described in article 16 of these general terms and conditions; b. any act or neglect by the customer, their subordinates, or other persons employed by or on behalf of the customer.\n\n11.2. chouxpastry.nl is not liable for any damage caused by the temporary or permanent unavailability of the ordering option, inaccessibility, or removal of its website due to maintenance or otherwise.\n\n11.3. The colors seen on the customer\'s screen may differ from the colors the product actually has. chouxpastry.nl is not liable for such color deviations.\n\n11.4. Delivered choux pastry has a limited shelf life. chouxpastry.nl accepts no liability if the delivered choux pastry is consumed after the standard shelf life period.\n\n11.5. The customer is responsible for checking whether the delivered product contains ingredients to which the customer is allergic or from which the customer can become ill.\n\n11.6. chouxpastry.nl is not liable for damage of any kind because chouxpastry.nl has assumed incorrect and/or incomplete information provided by the customer, such as a wrongly specified delivery address.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 12. Force Majeure\n\n12.1. In case of force majeure, chouxpastry.nl is not obliged to compensate for any damage caused to the customer unless chouxpastry.nl has had an advantage due to the force majeure situation that she would not have had if she had performed correctly.\n\n12.2. Force majeure on the part of chouxpastry.nl exists if chouxpastry.nl is prevented from fulfilling her obligations under the agreement or the preparation thereof as a result of: war, war danger, riots, revolution, turmoil, fire, water damage, flooding, government measures, import and export barriers, defects in materials, unavailability of materials, strikes, blockades, company occupation, irreplaceable employees, transport difficulties due to weather conditions and traffic disruption, both in the company of chouxpastry.nl and in the company of third parties involved in the agreement.\n\n12.3. Force majeure also includes a failure by suppliers of chouxpastry.nl as a result of which chouxpastry.nl cannot fulfill her obligations in time or not fully.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 13. Intellectual Property Rights\n\n13.1. The customer must fully and unconditionally respect all intellectual property rights that rest on the products delivered by chouxpastry.nl.\n\n13.2. Without prior written or electronic permission from chouxpastry.nl, the customer may not copy, forward, distribute, reproduce, or publish any information, texts, logos, trademarks, trade names, and images that he has obtained via the website.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 14. Confidentiality\n\n14.1. Both parties are obliged to secrecy of all confidential information that they have obtained from each other or from another source in the context of their agreement. Information is considered confidential if this has been communicated by the other party or if this results from the nature of the information. The party receiving confidential information will only use it for the purpose for which it was provided.\n\n14.2. chouxpastry.nl processes personal data in accordance with relevant legislation and regulations (Wet bescherming persoonsgegevens - Dutch Personal Data Protection Act).\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 15. Security and Internet\n\n15.1. chouxpastry.nl will take appropriate security measures to protect the website against the risks of unauthorized access to or modification, destruction, or loss of the data entered by the customer via the website, but chouxpastry.nl cannot give any guarantee regarding this.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    {'Article 16. Applicable Law and Competent Court\n\n16.1. Dutch law applies to every agreement between chouxpastry.nl and the customer.\n\n'.split('\n').map((text, index) => <div key={index}>{text}<br /></div>)}
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

export default Modalpopup;