import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Eerste from "../Assets/versierdeSoes.jpeg";
import Tweede from "../Assets/testing1.jpeg";
import Derde from "../Assets/kaleSoes.jpeg";



const About = () => {

  return (
    <div id="sectionAbout" className="home-banner-container">
 <Carousel autoPlay={true} interval={2000} dynamicHeight={true} infiniteLoop={true} showArrows={false} showIndicators={false} showThumbs={false} showStatus={false}>
      <div>
         <img src={Tweede} />
       </div>
       <div>
         <img src={Derde} />
       </div>
      <div>
      <img src={Eerste} />
     </div>
   </Carousel>  
    </div>
  );
};

export default About;
