import React from "react";
import Logo from "../Assets/Choux_Pastry_Logo-removebg.png";

const Banner = () => {
  return (
    <div id="" className="banner">
      <div className="banner">
        <img src={Logo} alt="" />
      </div>
    </div>
  );
};


export default Banner;
