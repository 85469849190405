import React from "react";
import { SocialIcon } from "react-social-icons";

const Work = () => {
  
  return (
    <div id="sectionContact" className="work-section-wrapper">
      <div className="work-section-top">
        <h1 className="primary-heading">Contact</h1>
        <p className="primary-text">
        Craving something deliciously different? <br/> 
Choose your preferred way to connect with us below and let's start a flavorful journey together!
        </p>
      </div>
      <div className="work-section-bottom">
        <a href="//api.whatsapp.com/send?phone=31617483685">
          <div className="work-section-info">
            <div className="info-boxes-img-container">
              <SocialIcon bgColor="#075E54" network="whatsapp" style={{ height: 125, width: 125, cursor: "pointer" }} />
            </div>
          </div>
          </a>
          <a href="Mailto:shouxpastrys@hotmail.com">
          <div className="work-section-info">
            <div className="info-boxes-img-container">
              <SocialIcon network="email" style={{ height: 125, width: 125, cursor: "pointer" }} />
            </div>
          </div>
          </a>
          <a href="https://www.instagram.com/choux_pastrys/" target="_blank">
          <div className="work-section-info">
            <div className="info-boxes-img-container">
              <SocialIcon network="instagram" style={{ height: 125, width: 125, cursor: "pointer" }} />
            </div>
          </div>
          </a>
      </div>
    </div>
  );
};

export default Work;
