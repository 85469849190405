import "./App.css";
import Home from "./Components/Home";
import Work from "./Components/Work";
import Content from "./Components/Content";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Me from "./Components/Me";
import ScrollIndicator from "./Components/ScrollIndicator";

function App() {
  return (
    <div className="App">
      <ScrollIndicator />
      <Home />
      <About />
      <Me />
      <Content />
      <Work />
      <Footer />
    </div>
  );
}


export default App;
