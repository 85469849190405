import React from "react";
import Logo from "../Assets/Halal_logo.svg.png";

const Me = () => {
 
  return (
    <div id="sectionAbout" className="work-section-wrapper">
      <div className="work-section-top">
        <h1 className="primary-heading">About</h1>
        <p className="primary-text">
        <p>A savory, luxurious, <strong>halal</strong> snack suitable for any occasion. I have been baking choux since I was 19. A perfect choux is airy on the inside with a shiny, golden-brown exterior. And I love filling them with a creamy chicken filling.
<br /> <br />
Baking is my joy in my spare time. Every setback has only made me better. Over the years, my choux pastries have evolved from being flat to perfect. The filling has been a perfect match since day one. It is not too meaty, not too sour, but the perfect balance of flavors to complete my choux.
<br /> <br />
I'm willing to bet that you will enjoy these choux pastries more often once you have tasted them!
<br/> <br />
<strong>Price: €29,95 per 30 pieces</strong></p>
        </p>
      </div>
      <div className="work-section-bottom">
          <div className="work-section-info">
            <div className="info-boxes-img-container">
            <img src={Logo} alt=""  style={{height: 125, width: 125}}/>
            </div>
          </div>
          </div>
    </div>
  );
};


export default Me;
